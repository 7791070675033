import React, { Ref } from "react";
import { css } from "styled-components";

export const HideScroll = React.forwardRef(
  (
    props: {
      className?: string;
      children: React.ReactNode | React.ReactNode[];
    },
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <div
        className={props.className}
        css={css`
          overflow: hidden;
          height: 100%;
        `}
      >
        <div
          ref={ref}
          css={css`
            overflow: auto;
            height: 100%;
            margin-bottom: -18px;
            padding-right: 20px;
            margin-right: -18px;
          `}
        >
          {props.children}
        </div>
      </div>
    );
  }
);
