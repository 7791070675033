import React, { useState } from "react";
import { Link } from "./Link";
import { HeaderNav } from "./HeaderNav";
import { AccountNav } from "./HeaderAccountNav";
import { css } from "styled-components";
import { Colors } from "../branding";
import SearchIcon from "../icons/SearchIcon";
import LogoIcon from "../icons/LogoIcon";
import Headroom from "react-headroom";
import { Wrapper } from "../components/Wrapper";
import { Row, Spacing } from "../helpers/layout";
import { useCommonData } from "../context/CommonDataContext";
import { AnnouncementsRenderer } from "./AnnouncementsRenderer";
import { Announcement } from "../types/Announcement";
import { MenuItem } from "../types/Menu";
import { useDebounce } from "../helpers/useDebounce";
import { useKeyPress } from "../helpers/useKeyPress";
import { PlainButton } from "./PlainButton";
import { HEADER_DESKTOP_LEVEL } from "../helpers/zIndex";
import Router from "next/router";
import { useBlockScroll } from "../helpers/useBlockScroll";

const HeaderDesktop = React.memo(
  (props: {
    headerStyle?: "DEFAULT" | "DARK" | "OVER";
    announcements: Announcement[];
  }) => {
    const { headerMenu } = useCommonData();
    const [ihovered, setHovered] = useState<MenuItem | null>(null);
    const hovered = useDebounce(ihovered, 200);
    const [scrolled, setScrolled] = useState(false);
    const darkMode = props.headerStyle === "DARK" && !scrolled;
    const overMode = props.headerStyle === "OVER" && !scrolled;
    const themeColor = darkMode && !hovered ? Colors.white : Colors.black;
    const fixed = props.headerStyle === "DARK" || props.headerStyle === "OVER";
    const blockScroll =
      hovered && hovered.children && hovered.children.length ? true : false;

    useBlockScroll(blockScroll);

    useKeyPress("Escape", () => {
      setHovered(null);
    });

    const hasAnnouncements =
      props.announcements && props.announcements.length > 0;

    const fixedStyles = css`
      .headroom-wrapper {
        height: auto !important;
      }
      .headroom--unfixed {
        position: absolute !important;
        top: ${hasAnnouncements ? 32 : 0}px !important;
      }
    `;

    return (
      <div
        css={css`
          position: relative;
          z-index: ${HEADER_DESKTOP_LEVEL};

          ${fixed && fixedStyles}
        `}
      >
        {hasAnnouncements && (
          <AnnouncementsRenderer announcements={props.announcements} />
        )}
        <Headroom
          pinStart={hasAnnouncements ? 40 : 0}
          onPin={() => {
            setScrolled(true);
            document.body.classList.add("header-pinned");
          }}
          onUnpin={() => {
            document.body.classList.remove("header-pinned");
          }}
          onUnfix={() => setScrolled(false)}
        >
          <header
            css={css`
              width: 100%;
              background: ${darkMode || overMode ? "transparent" : "#fff"};
              color: ${themeColor};

              .header-nav-link {
                color: ${themeColor};
              }

              svg {
                path {
                  fill: ${themeColor};
                }
              }
            `}
          >
            <Wrapper type="header">
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  height: 70px;
                `}
              >
                <div
                  css={css`
                    flex: 0 0 auto;
                  `}
                >
                  <Row
                    align="center"
                    gutter={Spacing.l}
                    onMouseLeave={() => setHovered(null)}
                  >
                    <div
                      css={css`
                        position: relative;
                        z-index: 1;
                      `}
                    >
                      <Link href="/" noEffect>
                        <LogoIcon />
                      </Link>
                    </div>

                    <div>
                      {headerMenu && (
                        <HeaderNav
                          menu={headerMenu}
                          hovered={hovered}
                          setHovered={(hovered) => setHovered(hovered)}
                          hasAnnouncements={hasAnnouncements}
                        />
                      )}
                    </div>
                  </Row>
                </div>
                <div
                  css={css`
                    flex: 0 0 auto;
                  `}
                >
                  <Row
                    align="center"
                    css={css`
                      position: relative;
                    `}
                    gutter={Spacing.s}
                  >
                    <div>
                      <AccountNav />
                    </div>
                    <div
                      css={css`
                        width: 25px;
                        text-align: right;
                      `}
                    >
                      <PlainButton
                        onClick={() => {
                          Router.push("/search");
                        }}
                        icon={<SearchIcon />}
                        css={css`
                          margin-left: ${Spacing.s};
                        `}
                      ></PlainButton>
                    </div>
                  </Row>
                </div>
              </div>
            </Wrapper>
          </header>
        </Headroom>
      </div>
    );
  }
);

export default HeaderDesktop;
