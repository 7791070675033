import React, { useState, useEffect, useRef } from "react";
import { MenuItem, Menu } from "../types/Menu";
import { Wrapper } from "./Wrapper";
import { Spacing, Row, Column } from "../helpers/layout";
import { css } from "styled-components";
import { Colors } from "../branding";
import { Link } from "./Link";
import { HeaderInnerDropdown } from "./HeaderInnerDropdown";
import { Search } from "./Search";
import { useDebounce } from "../helpers/useDebounce";
import ArrowTiny from "../static/images/arrowTiny.png";
import { HideScroll } from "./HideScroll";
import throttle from "lodash/throttle";
import { HeaderBottomMenu } from "./HeaderBottomMenu";
import { hasDropdown } from "../helpers/menu";

function getChildrenNum(item: MenuItem): number {
  if (!item.children) return 0;

  return item.children.reduce((childrenNum, item) => {
    const addition = item.type === "title" ? 0 : 1;

    return childrenNum + addition + getChildrenNum(item);
  }, 0);
}

export const HeaderComplexDropdown = React.memo(function (props: {
  hasAnnouncements: boolean;
  items: MenuItem[];
  menu: Menu | null;
  onMouseLeave: () => void;
  onMouseEnter: () => void;
}) {
  const [ihovered, setHovered] = useState<MenuItem | null>(null);
  const scrollContainer = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const hovered = useDebounce(ihovered, 300);
  const [scrollHeight, setScrollHeight] = useState(0);

  useEffect(() => {
    setVisible(true);
  }, []);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const el = document.documentElement;
      const scrollTop = document.body.scrollTop || el.scrollTop;
      const offset = props.hasAnnouncements ? 40 : 0;

      setScrollHeight(offset - scrollTop > 0 ? offset - scrollTop : 0);
    }, 200);

    window.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [props.hasAnnouncements]);

  return (
    <div
      css={css`
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: ${`calc(100vh - 100% - ${scrollHeight}px) `};
        opacity: ${visible ? 1 : 0};
        visibility: ${visible ? "visible" : "hidden"};
        color: ${Colors.black};
        transition: opacity 500ms;

        svg {
          path {
            fill: ${Colors.black};
          }
        }
      `}
      onMouseEnter={() => {
        props.onMouseEnter();
      }}
    >
      <div
        css={css`
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.68);

          &:before {
            position: absolute;
            display: block;
            width: 100%;
            height: 70px;
            top: -70px;
            background: inherit;
            content: "";
            pointer-events: none;
          }
        `}
        onMouseEnter={() => {
          props.onMouseLeave();
        }}
      ></div>
      <div
        css={css`
          position: absolute;
          width: ${visible
            ? hovered && hasDropdown(hovered)
              ? "100%"
              : "calc(100% - 250px)"
            : "0%"};
          height: 100%;
          top: 0;
          left: 0;
          background: #fff;
          transition: width 500ms;

          &:before {
            position: absolute;
            display: block;
            width: 100%;
            height: 70px;
            top: -70px;
            background: inherit;
            content: "";
            pointer-events: none;
          }
        `}
      ></div>
      <div
        css={css`
          position: relative;
          padding: ${Spacing.xl} 0;
          height: 100%;
          box-sizing: border-box;
          pointer-events: none;
        `}
      >
        <Wrapper
          type="header"
          css={css`
            height: 100%;
          `}
        >
          <Row
            css={css`
              height: 100%;
            `}
            gutter={Spacing.none}
          >
            <HideScroll
              css={css`
                flex: 0 0 25%;
                width: 25%;
                max-width: 250px;
                height: 100%;
              `}
            >
              <Column
                justify="space-between"
                css={css`
                  height: 100%;
                `}
              >
                <ul
                  css={css`
                    list-style: none;
                    padding: 0;
                    pointer-events: all;

                    li {
                      margin-bottom: ${Spacing.l};

                      &:last-child {
                        margin-bottom: 0;
                      }
                    }
                  `}
                >
                  {props.items.map((item, i) => {
                    const isActive = hovered && hovered.uuid === item.uuid;
                    const childrenNum = getChildrenNum(item);

                    return (
                      <li
                        key={i}
                        css={css`
                          margin-bottom: ${Spacing.xl};
                        `}
                        onMouseEnter={() => {
                          setHovered(item);
                        }}
                        onMouseLeave={() => {
                          setHovered(null);
                        }}
                      >
                        <Link
                          external={item.new_window}
                          href={item.type === "search" ? "/search" : item.url}
                          noEffect
                          disable={item.disable_click}
                          css={css`
                            font-size: 12px;
                            line-height: 20px;
                            text-decoration: none;
                            cursor: pointer;
                            position: relative;
                            font-weight: 500;
                            left: -35px;
                            transition: all 400ms;
                            text-transform: uppercase;
                            letter-spacing: 0.15em;

                            &:last-child {
                              margin-bottom: 0;
                            }

                            .arrow {
                              margin-right: ${Spacing.m};
                              position: relative;
                              opacity: 0;
                              visibility: hidden;
                            }

                            ${isActive &&
                            css`
                              left: 0;

                              .arrow {
                                opacity: 1;
                                visibility: visible;
                              }
                            `}
                          `}
                        >
                          <span className="arrow">
                            <img src={ArrowTiny} width="20px" alt="Arrow" />
                          </span>
                          {item.name}
                          <span
                            css={css`
                              font-size: 10px;
                              position: absolute;
                              left: 100%;
                              margin-left: 5px;
                              top: -7px;
                            `}
                          >
                            {childrenNum > 0 && childrenNum}
                          </span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                <div
                  css={css`
                    pointer-events: all;
                  `}
                >
                  {props.menu && <HeaderBottomMenu items={props.menu.items} />}
                </div>
              </Column>
            </HideScroll>

            <div
              css={css`
                flex: 1 0 60%;
                height: 100%;
                pointer-events: all;
                display: ${hovered ? "block" : "none"};
              `}
              onMouseEnter={() => setHovered(hovered)}
            >
              <HideScroll
                css={css`
                  width: 100%;
                  max-width: 875px;
                `}
                ref={scrollContainer}
              >
                {hovered ? (
                  hovered.type === "search" ? (
                    <Search
                      arrowsInside
                      minimal
                      scrollContainer={scrollContainer.current}
                    />
                  ) : hovered.children && hovered.children.length > 0 ? (
                    <HeaderInnerDropdown
                      items={hovered.children}
                      key={hovered.uuid}
                    />
                  ) : null
                ) : null}
              </HideScroll>
            </div>
          </Row>
        </Wrapper>
      </div>
    </div>
  );
});
