import React from "react";
import { css } from "styled-components";
import { Colors } from "../branding";
import { useResponsive } from "../context/ResponsiveContext";

export function Copyright() {
  const { isMobile, isTablet } = useResponsive();
  return (
    <div
      css={css`
        color: ${Colors.grey_chateau};
      `}
    >
      {isMobile ? (
        <div
          css={css`
            font-size: 10px;
          `}
        >
          &copy; {new Date().getFullYear()} Drew Doggett Photography LLC
        </div>
      ) : isTablet ? (
        <div
          css={css`
            font-size: 14px;
          `}
        >
          Copyright {new Date().getFullYear()} by Drew Doggett Photography
        </div>
      ) : (
        <p>
          Copyright {new Date().getFullYear()} by Drew Doggett Photography,
          <br /> LLC. All Rights Reserved.
        </p>
      )}
    </div>
  );
}
