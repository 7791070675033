import { MenuItem } from "../types/Menu";

export function hasDropdown(item: MenuItem) {
  if (item.children && item.children.length) return true;

  if (item.menu_item_cover) return true;

  if (item.type === "search") return true;

  return false;
}
