import React, { useEffect } from "react";
import { Announcement } from "../types/Announcement";
import { useResponsive } from "../context/ResponsiveContext";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";
import Router, { useRouter } from "next/router";
import { mixpanelEvent } from "../helpers/analytics";
import { useModal } from "../context/ModalContext";

function cleanUrl(path: string) {
  return path.replace(/\?.*$/, "");
}

const Header = (props: {
  headerStyle?: "DEFAULT" | "DARK" | "OVER";
  announcements: Announcement[];
}) => {
  const { isTablet, isMobile } = useResponsive();
  const { asPath } = useRouter();
  const { flushModalsOpened } = useModal();

  useEffect(() => {
    const scrollToTop = (path: string) => {
      const newPath = cleanUrl(path);
      const currentPath = cleanUrl(asPath);

      if (newPath !== currentPath) {
        window.scrollTo(0, 0);
      }
    };

    const onRouteChange = (path: string) => {
      scrollToTop(path);
      flushModalsOpened();
      mixpanelEvent("Page viewed", { path });

      // // deprecated
      // gaSet(path);
      // gaPageview();
    };

    Router.events.on("routeChangeStart", onRouteChange);

    return () => {
      Router.events.off("routeChangeStart", onRouteChange);
    };
  }, [asPath]);

  if (isTablet || isMobile) {
    return (
      <HeaderMobile
        headerStyle={props.headerStyle}
        announcements={props.announcements}
      />
    );
  }

  return (
    <HeaderDesktop
      headerStyle={props.headerStyle}
      announcements={props.announcements}
    />
  );
};

export default Header;
