const MenuIcon = () => (
  <svg width="18px" viewBox="0 0 40 33" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="#000000" fillRule="nonzero">
        <path d="M1.85882353,3.62352941 C5.95294118,3.62352941 10.0588235,3.62352941 14.1529412,3.62352941 C20.6470588,3.62352941 27.1294118,3.62352941 33.6235294,3.62352941 C35.1294118,3.62352941 36.6235294,3.62352941 38.1294118,3.62352941 C39.0470588,3.62352941 39.9411765,2.81176471 39.8941176,1.85882353 C39.8470588,0.905882353 39.1176471,0.0941176471 38.1294118,0.0941176471 C34.0352941,0.0941176471 29.9294118,0.0941176471 25.8352941,0.0941176471 C19.3411765,0.0941176471 12.8588235,0.0941176471 6.36470588,0.0941176471 C4.85882353,0.0941176471 3.36470588,0.0941176471 1.85882353,0.0941176471 C0.941176471,0.0941176471 0.0470588235,0.905882353 0.0941176471,1.85882353 C0.141176471,2.81176471 0.870588235,3.62352941 1.85882353,3.62352941 L1.85882353,3.62352941 Z"></path>
        <path d="M1.85882353,17.4941176 C5.95294118,17.4941176 10.0588235,17.4941176 14.1529412,17.4941176 C20.6470588,17.4941176 27.1294118,17.4941176 33.6235294,17.4941176 C35.1294118,17.4941176 36.6235294,17.4941176 38.1294118,17.4941176 C39.0470588,17.4941176 39.9411765,16.6823529 39.8941176,15.7294118 C39.8470588,14.7764706 39.1176471,13.9647059 38.1294118,13.9647059 C34.0352941,13.9647059 29.9294118,13.9647059 25.8352941,13.9647059 C19.3411765,13.9647059 12.8588235,13.9647059 6.36470588,13.9647059 C4.85882353,13.9647059 3.36470588,13.9647059 1.85882353,13.9647059 C0.941176471,13.9647059 0.0470588235,14.7764706 0.0941176471,15.7294118 C0.141176471,16.6823529 0.870588235,17.4941176 1.85882353,17.4941176 L1.85882353,17.4941176 Z"></path>
        <path d="M1.85882353,32.3764706 C5.95294118,32.3764706 10.0588235,32.3764706 14.1529412,32.3764706 C20.6470588,32.3764706 27.1294118,32.3764706 33.6235294,32.3764706 C35.1294118,32.3764706 36.6235294,32.3764706 38.1294118,32.3764706 C39.0470588,32.3764706 39.9411765,31.5647059 39.8941176,30.6117647 C39.8470588,29.6588235 39.1176471,28.8470588 38.1294118,28.8470588 C34.0352941,28.8470588 29.9294118,28.8470588 25.8352941,28.8470588 C19.3411765,28.8470588 12.8588235,28.8470588 6.36470588,28.8470588 C4.85882353,28.8470588 3.36470588,28.8470588 1.85882353,28.8470588 C0.941176471,28.8470588 0.0470588235,29.6588235 0.0941176471,30.6117647 C0.141176471,31.5647059 0.870588235,32.3764706 1.85882353,32.3764706 L1.85882353,32.3764706 Z"></path>
      </g>
    </g>
  </svg>
);
export default MenuIcon;
