import { css } from "styled-components";
import { Spacing, Column } from "../helpers/layout";
import { Colors } from "../branding";
import { Title, fontFamilies } from "../typography";
import { SubscribeForm } from "../forms/SubscribeForm";
import { useCommonData } from "../context/CommonDataContext";
import FbIcon from "../static/images/fb.png";
import InstIcon from "../static/images/insta.png";
import PinIcon from "../static/images/pin.png";
import { Wrapper } from "./Wrapper";
import { Link } from "./Link";
import { Copyright } from "./Copyright";
import { useResponsive } from "../context/ResponsiveContext";

export const FooterSmall = () => {
  const { footerSmallMenu, settings } = useCommonData();
  const { isMobile, isTablet } = useResponsive();

  return (
    <footer
      css={css`
        padding: ${Spacing.l} 0;
        box-sizing: border-box;
        background: ${Colors.black};
        color: ${Colors.white};
        position: relative;
        width: 100%;
        p {
          font-size: 15px;
          letter-spacing: 0.05em;
        }
        height: auto;
        text-align: center;
      `}
    >
      <Wrapper>
        <Column align="center" justify="center" gutter={Spacing.l}>
          <Title
            css={css`
              text-transform: uppercase;
            `}
          >
            Join The Adventure
          </Title>

          <div
            css={css`
              margin-left: auto;
              margin-right: auto;
              max-width: 300px;
            `}
          >
            <SubscribeForm />
          </div>

          {settings && (
            <div
              css={css`
                padding: ${Spacing.m} 0;
              `}
            >
              {settings.instagram_url && (
                <a
                  href={settings.instagram_url}
                  target="_blank"
                  css={css`
                    margin-right: ${Spacing.xl};
                  `}
                >
                  <img height="18" alt="instagram-logo" src={InstIcon} />
                </a>
              )}
              {settings.facebook_url && (
                <a
                  href={settings.facebook_url}
                  target="_blank"
                  css={css`
                    margin-right: ${Spacing.xl};
                  `}
                >
                  <img height="18" alt="facebook-logo" src={FbIcon} />
                </a>
              )}

              {settings.pinterest_url && (
                <a href={settings.pinterest_url} target="_blank">
                  <img height="18" alt="pinterest-logo" src={PinIcon} />
                </a>
              )}
            </div>
          )}
          {footerSmallMenu &&
            footerSmallMenu.items &&
            footerSmallMenu.items.length && (
              <div>
                <ul
                  css={css`
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    ${isMobile &&
                    css`
                      justify-content: space-between;
                    `}
                  `}
                >
                  {footerSmallMenu.items.map(({ name, url, new_window }) => (
                    <li
                      key={name}
                      css={
                        isTablet &&
                        css`
                          flex: 1 0 10%;
                          text-align: center;
                        `
                      }
                    >
                      <Link
                        href={url}
                        external={new_window}
                        css={css`
                          color: ${Colors.white};
                          text-transform: uppercase;
                          font-family: ${fontFamilies.gotham};
                          font-weight: 400;
                          font-size: ${isMobile ? "10px" : "15px"};
                          letter-spacing: 0.2em;
                        `}
                      >
                        {name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          <Copyright />
        </Column>
      </Wrapper>
    </footer>
  );
};
