import React from "react";
import styled, { css } from "styled-components";
import { Colors } from "../branding";
import { Link } from "./Link";
import { Copyright } from "./Copyright";
import { SubscribeForm } from "../forms/SubscribeForm";
import { fontFamilies } from "../typography";
import { Spacing, Column, Row } from "../helpers/layout";
import { useCommonData } from "../context/CommonDataContext";
import FbIcon from "../static/images/fb.png";
import InstIcon from "../static/images/insta.png";
import PinIcon from "../static/images/pin.png";
import { Wrapper } from "./Wrapper";
import { useResponsive } from "../context/ResponsiveContext";
import { FooterSmall } from "./FooterSmall";

const Item = styled.div`
  flex: 1 0 30%;
  box-sizing: border-box;
  border-right: 1px solid ${Colors.mortar};
  text-align: center;
  padding: 0 ${Spacing.xl};
  height: 300px;

  &:first-child {
    padding-left: 0;
  }

  :last-child {
    border: none;
    padding-right: 0;
  }
`;

const Title = styled.h2`
  font-family: ${fontFamilies.freight};
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 0.1em;
  line-height: 47px;
`;

const Footer = () => {
  const { footerMenu, settings } = useCommonData();
  const { isMobile, isTablet } = useResponsive();

  if (isMobile || isTablet) {
    return <FooterSmall />;
  }

  return (
    <footer
      css={css`
        padding: ${Spacing.xxl} 0;
        box-sizing: border-box;
        background: ${Colors.black};
        color: ${Colors.white};
        position: relative;
        width: 100%;
        p {
          font-size: 15px;
          letter-spacing: 0.05em;
        }
        height: auto;
      `}
    >
      <Wrapper>
        <Row gutter={Spacing.none} wrap="wrap">
          <Item>
            <Column justify="space-between">
              <Title
                css={css`
                  text-transform: uppercase;
                `}
              >
                Join The Adventure
              </Title>
              <p>
                Become an insider to receive exclusive first access to new
                series, promotions, and more.
              </p>
              <div
                css={css`
                  margin: 0 auto;
                  margin-top: ${Spacing.m};
                  max-width: 300px;
                `}
              >
                <SubscribeForm />
              </div>
            </Column>
          </Item>
          <Item>
            <Column justify="space-between">
              <Title>CONTACT US</Title>
              <div>
                <p>
                  To make an inquiry, request a custom quote or just keep in
                  touch, please contact us at:
                </p>
                <p>
                  <Link
                    css={css`
                      text-decoration: none;
                      color: ${Colors.white};
                      transition: color;
                      transition-duration: 0.2s;
                      :hover {
                        color: ${Colors.dark_gray};
                      }
                    `}
                    href="mailto:gallery@drewdoggett.com"
                    pure
                    underlined
                  >
                    gallery@drewdoggett.com
                  </Link>
                </p>
              </div>
              <ul
                css={css`
                  list-style: none;
                  padding: 0;
                `}
              >
                {footerMenu &&
                  footerMenu.items.map(({ name, url, new_window }) => (
                    <li
                      key={name}
                      css={css`
                        margin-top: ${Spacing.m};
                      `}
                    >
                      <Link
                        href={url}
                        external={new_window}
                        css={css`
                          color: ${Colors.white};
                          text-transform: uppercase;
                          transition: color;
                          transition-duration: 0.2s;
                          font-family: ${fontFamilies.gotham};
                          font-weight: 400;
                          font-size: 18px;
                          letter-spacing: 0.2em;

                          :hover {
                            color: ${Colors.dark_gray};
                          }
                        `}
                      >
                        {name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </Column>
          </Item>
          <Item>
            <Column
              justify="flex-start"
              css={css`
                height: 100%;
              `}
            >
              <Title
                css={css`
                  text-transform: uppercase;
                `}
              >
                Follow Drew
              </Title>
              {settings && (
                <div>
                  {settings.instagram_url && (
                    <a
                      href={settings.instagram_url}
                      target="_blank"
                      css={css`
                        margin-right: ${Spacing.xl};
                      `}
                    >
                      <img height="18" alt="instagram-logo" src={InstIcon} />
                    </a>
                  )}
                  {settings.facebook_url && (
                    <a
                      href={settings.facebook_url}
                      target="_blank"
                      css={css`
                        margin-right: ${Spacing.xl};
                      `}
                    >
                      <img height="18" alt="facebook-logo" src={FbIcon} />
                    </a>
                  )}

                  {settings.pinterest_url && (
                    <a href={settings.pinterest_url} target="_blank">
                      <img height="18" alt="pinterest-logo" src={PinIcon} />
                    </a>
                  )}
                </div>
              )}

              <Copyright />
            </Column>
          </Item>
        </Row>
      </Wrapper>
    </footer>
  );
};

export default Footer;
