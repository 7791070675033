import React from "react";
import { css } from "styled-components";
import { Menu, MenuItem } from "../types/Menu";
import { HeaderSimpleDropdown } from "./HeaderSimpleDropdown";
import { Link, getUrl } from "./Link";
import { HeaderComplexDropdown } from "./HeaderComplexDropdown";
import { Spacing } from "../helpers/layout";
import { useInteriorDesigner } from "../context/InteriorDesignerContext";
import { useCommonData } from "../context/CommonDataContext";

function getDepth(item: MenuItem): number {
  if (!item.children || !item.children.length) return 0;

  return (
    1 +
    Math.max(
      ...item.children.map((i) => {
        return getDepth(i);
      })
    )
  );
}

export const HeaderNav = React.memo(function (props: {
  menu: Menu;
  hovered: MenuItem | null;
  setHovered: (hovered: MenuItem | null) => void;
  hasAnnouncements: boolean;
}) {
  const { settings } = useCommonData();
  const { hovered, setHovered } = props;
  const { openInteriorDesigner } = useInteriorDesigner();

  return (
    <nav>
      <ul
        css={css`
          list-style: none;
          display: flex;
          padding: 0;
          margin: 0;
        `}
      >
        {props.menu.items.map((item) => {
          const { type, name, new_window } = item;
          const url = getUrl(item);
          const maxDepth = getDepth(item);

          const linkStyle = css`
            line-height: 70px;
            cursor: pointer;
            color: currentColor;
          `;

          return (
            <li
              css={css`
                padding: 0 ${Spacing.m};
              `}
              key={name}
            >
              <div
                css={css`
                  position: relative;
                  z-index: 1;
                  font-size: 12px;
                  text-transform: uppercase;
                  letter-spacing: 0.15em;
                  text-decoration: none;
                `}
                onMouseEnter={() => setHovered(item)}
              >
                {url ? (
                  <Link
                    href={url}
                    css={linkStyle}
                    external={new_window}
                    noEffect
                    disable={item.disable_click}
                    className="header-nav-link"
                  >
                    {name}
                  </Link>
                ) : type === "interior_designer" && !settings.gallery_focus ? (
                  <a
                    css={linkStyle}
                    className="header-nav-link"
                    onClick={() => {
                      openInteriorDesigner();
                    }}
                  >
                    {name}
                  </a>
                ) : null}
              </div>
              {maxDepth === 1 && item.children && hovered === item && (
                <HeaderSimpleDropdown
                  items={item.children}
                  onMouseLeave={() => {
                    setHovered(null);
                  }}
                  onMouseEnter={() => setHovered(item)}
                />
              )}
              {maxDepth > 1 && item.children && hovered === item && (
                <HeaderComplexDropdown
                  menu={item.menu}
                  hasAnnouncements={props.hasAnnouncements}
                  items={item.children}
                  onMouseLeave={() => {
                    setHovered(null);
                  }}
                  onMouseEnter={() => setHovered(item)}
                />
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
});
