import React, { useState, useEffect, useMemo } from "react";
import { MenuItem } from "../types/Menu";
import { Wrapper } from "./Wrapper";
import { Spacing } from "../helpers/layout";
import { css } from "styled-components";
import { Link, getLinkParams, getUrl } from "./Link";
import { Colors } from "../branding";
import ArrowTiny from "../static/images/arrowTiny.png";
import { HideScroll } from "./HideScroll";
import { useDebounce } from "../helpers/useDebounce";
import { ItemPreview } from "./HeaderInnerDropdown";
import Router from "next/router";
import { hasDropdown } from "../helpers/menu";

export function HeaderSimpleDropdown(props: {
  items: MenuItem[];
  onMouseLeave: () => void;
  onMouseEnter: () => void;
}) {
  const [visible, setVisible] = useState(false);
  const [ihovered, setHovered] = useState<MenuItem | null>(null);
  const hovered = useDebounce(ihovered, 300);

  const isHovered = useMemo(() => {
    return hovered && hasDropdown(hovered);
  }, [hovered]);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <div
      css={css`
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: calc(100vh - 100%);
        opacity: ${visible ? 1 : 0};
        visibility: ${visible ? "visible" : "hidden"};
        color: ${Colors.black};
        transition: opacity 500ms;
      `}
      onMouseEnter={() => {
        props.onMouseEnter();
      }}
    >
      <div
        css={css`
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.68);

          &:before {
            position: absolute;
            display: block;
            width: 100%;
            height: 70px;
            top: -70px;
            background: inherit;
            content: "";
            pointer-events: none;
          }
        `}
        onMouseEnter={() => {
          props.onMouseLeave();
        }}
      ></div>
      <div
        css={css`
          position: absolute;
          width: ${visible
            ? isHovered
              ? "100%"
              : "calc(100% - 250px)"
            : "0%"};
          height: 100%;
          top: 0;
          left: 0;
          background: #fff;
          transition: width 500ms;

          &:before {
            position: absolute;
            display: block;
            width: 100%;
            height: 70px;
            top: -70px;
            background: inherit;
            content: "";
            pointer-events: none;
          }
        `}
      ></div>
      <Wrapper
        type="header"
        css={css`
          height: 100%;
          display: flex;
          flex-flow: row nowrap;
        `}
      >
        <HideScroll
          css={css`
            position: relative;
            height: 100%;
            padding: ${Spacing.xl} 0;
          `}
        >
          <ul
            css={css`
              list-style: none;
              padding: 0;

              li {
                margin-bottom: ${Spacing.xl};

                &:last-child {
                  margin-bottom: 0;
                }
              }
            `}
          >
            {props.items.map((item, i) => {
              return (
                <li
                  key={i}
                  onMouseEnter={() => {
                    setHovered(item);
                  }}
                  css={css`
                    margin-bottom: ${Spacing.xl};

                    &:last-child {
                      margin-bottom: 0;
                    }
                  `}
                >
                  <Link
                    href={item.url}
                    external={item.new_window}
                    disable={item.disable_click}
                    noEffect
                    pure={item.new_window}
                    css={css`
                      position: relative;
                      font-size: 12px;
                      line-height: 20px;
                      font-weight: 500;
                      text-transform: uppercase;
                      letter-spacing: 0.15em;
                      left: -35px;
                      transition: all 400ms;

                      .arrow {
                        margin-right: ${Spacing.m};
                        opacity: 0;
                        visibility: hidden;
                      }

                      &:hover {
                        left: 0;

                        .arrow {
                          opacity: 1;
                          visibility: visible;
                        }
                      }
                    `}
                  >
                    <span className="arrow">
                      <img src={ArrowTiny} width="20px" alt="Arrow" />
                    </span>
                    {item.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </HideScroll>
        <div
          css={css`
            flex: 0 0 40%;
            z-index: 1;
            visibility: ${isHovered ? "visible" : "hidden"};
            opacity: ${isHovered ? 1 : 0};
            transition: opacity 500ms, visibility 500ms;
            transition-delay: ${isHovered ? "500ms" : 0};
          `}
        >
          {hovered && (
            <ItemPreview
              item={hovered}
              onClick={() => {
                const url = getUrl(hovered);

                if (url) {
                  const { href, as } = getLinkParams(url, hovered.new_window);

                  Router.push(href, as);
                }
              }}
            />
          )}
        </div>
      </Wrapper>
    </div>
  );
}
