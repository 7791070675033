import React, { useState } from "react";
import { MenuItem } from "../types/Menu";
import { Row, Spacing, Column } from "../helpers/layout";
import { css } from "styled-components";
import { Hr } from "./Hr";
import { fontFamilies } from "../typography";
import { Image } from "./Image";
import { getUrl, Link, getLinkParams } from "./Link";
import { HideScroll } from "./HideScroll";
import ArrowTiny from "../static/images/arrowTiny.png";
import { Button } from "./Button";
import Router from "next/router";
import { Media } from "../types/Media";
import { useResponsive } from "../context/ResponsiveContext";

function Item(props: { item: MenuItem }) {
  const { item } = props;
  const url = getUrl(item);

  const linkStyle = css`
    font-size: 12px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    cursor: pointer;
    transition: all 400ms;
    position: relative;
    left: -35px;
    transition: all 400ms;

    .arrow {
      margin-right: ${Spacing.m};
      position: relative;
      opacity: 0;
      visibility: hidden;
    }
    &:hover {
      left: 0;

      .arrow {
        opacity: 1;
        visibility: visible;
      }
    }
  `;

  if (url) {
    return (
      <Link
        href={url}
        external={item.new_window}
        css={linkStyle}
        disable={item.disable_click}
        noEffect
      >
        <Row gutter={Spacing.none}>
          <span className="arrow">
            <img src={ArrowTiny} width="20px" alt="Arrow" />
          </span>
          <span>{item.name}</span>
        </Row>
      </Link>
    );
  }

  if (item.type === "title") {
    return (
      <div
        css={css`
          letter-spacing: 0.05em;
          text-transform: uppercase;
          line-height: 20px;
          font-weight: 500;
          margin-top: ${Spacing.l};

          &:first-child {
            margin-top: 0;
          }
        `}
      >
        <h4>{item.name}</h4>
        <Hr size="small" />
      </div>
    );
  }

  return null;
}

type ItemPreviewProps = {
  item: MenuItem;
  onClick?: () => void;
};

function ItemPreviewContents({
  onClick,
  featuredImage,
  disableClick,
  part1,
  part2,
  description,
  btnLabel,
}: {
  onClick?: () => void;
  featuredImage: Media | null;
  disableClick: boolean;
  part1: string;
  part2?: string;
  description?: string | null;
  btnLabel: string;
}) {
  const { isMobile } = useResponsive();

  return (
    <Column>
      <div
        onClick={disableClick ? undefined : onClick}
        css={css`
          display: flex;
          cursor: ${onClick ? "pointer" : "default"};
        `}
      >
        <Image
          file={featuredImage}
          width={515}
          style={{
            width: "100%",
          }}
        />
      </div>
      <h3>
        <span
          css={css`
            font-family: ${fontFamilies.freight};
            font-weight: 600;
            font-size: 20px;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            margin-right: 10px;
          `}
        >
          {part1}
        </span>
        <span
          css={css`
            font-family: ${fontFamilies.gotham};
            font-size: 13px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            font-weight: 300;
          `}
        >
          {part2}
        </span>
      </h3>
      {!isMobile ? <Hr size="small" /> : null}
      {description && (
        <p
          css={css`
            font-family: ${fontFamilies.gotham};
            font-style: italic;
            font-size: 14px;
            letter-spacing: 0.01em;
            margin: 0;
          `}
        >
          {description}
        </p>
      )}
      {onClick && (
        <Button
          label={btnLabel}
          type="button"
          disabled={disableClick}
          onClick={onClick}
        />
      )}
      {isMobile ? <Hr size="small" /> : null}
    </Column>
  );
}

export function ItemPreview(props: ItemPreviewProps) {
  const { type, serie } = props.item;

  if (type === "serie" && serie) {
    const [part1, part2] = serie.name.split(": ");

    return (
      <ItemPreviewContents
        onClick={props.onClick}
        featuredImage={serie.featured_image}
        key={serie.id}
        disableClick={props.item.disable_click}
        part1={part1}
        part2={part2}
        description={serie.description}
        btnLabel="view series"
      />
    );
  }

  if (type !== "serie" && props.item.menu_item_cover) {
    const [part1, part2] =
      props.item.menu_item_cover_title?.split(": ") ||
      props.item.name.split(": ");
    return (
      <ItemPreviewContents
        onClick={props.onClick}
        featuredImage={props.item.menu_item_cover}
        key={props.item.uuid}
        disableClick={props.item.disable_click}
        part1={part1}
        part2={part2}
        description={props.item.menu_item_cover_description}
        btnLabel="View"
      />
    );
  }

  return null;
}

export function HeaderInnerDropdown(props: { items: MenuItem[] }) {
  const [hovered, setHovered] = useState<MenuItem | null>(null);

  return (
    <Row
      css={css`
        height: 100%;
      `}
    >
      <HideScroll
        css={css`
          flex: 0 0 40%;
          width: 40%;
        `}
      >
        <Column gutter={Spacing.xl}>
          {props.items.map((item, i) => {
            return (
              <div key={i}>
                <Column gutter={Spacing.l}>
                  <div onMouseEnter={() => setHovered(item)}>
                    <Item item={item} />
                  </div>
                  {item.children &&
                    item.children.map((item, i) => {
                      return (
                        <div key={i} onMouseEnter={() => setHovered(item)}>
                          <Item item={item} />
                        </div>
                      );
                    })}
                </Column>
              </div>
            );
          })}
        </Column>
      </HideScroll>

      <div
        css={css`
          flex: 1 0 40%;
          width: 40%;
        `}
      >
        {hovered && (
          <ItemPreview
            item={hovered}
            onClick={() => {
              const url = getUrl(hovered);

              if (url) {
                const { href, as } = getLinkParams(url, hovered.new_window);

                Router.push(href, as);
              }
            }}
          />
        )}
      </div>
    </Row>
  );
}
