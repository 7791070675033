import React, { useState, useEffect } from "react";
import { Announcement } from "../types/Announcement";
import { AnnouncementsRenderer } from "./AnnouncementsRenderer";
import { css } from "styled-components";
import Headroom from "react-headroom";
import { getLinkParams, getUrl, Link } from "./Link";
import LogoIcon from "../icons/LogoIcon";
import MenuIcon from "../icons/MenuIcon";
import { Colors } from "../branding";
import { Wrapper } from "./Wrapper";
import BagIcon from "../icons/BagIcon";
import { HeaderMobileDropdown } from "./HeaderMobileDropdown";
import { useCommonData } from "../context/CommonDataContext";
import CloseIcon from "../icons/CloseIcon";
import { Spacing } from "../helpers/layout";
import { MenuItem, Menu } from "../types/Menu";
import Router, { useRouter } from "next/router";
import { useInteriorDesigner } from "../context/InteriorDesignerContext";
import isArray from "lodash/isArray";
import ArrowLeft from "../icons/ArrowLeft";
import { HEADER_MOBILE_LEVEL } from "../helpers/zIndex";
import { useCart } from "../context/CartContext";
import { useWishlist } from "../context/WishlistContext";
import { useUserState } from "../context/UserContext";
import { PlainButton } from "./PlainButton";
import { useComparison } from "../context/ComparisonContext";
import { ItemPreview } from "./HeaderInnerDropdown";
import { useBlockScroll } from "../helpers/useBlockScroll";
import { Hr } from "./Hr";
import SearchIcon from "../icons/SearchIcon";

const iconStyles = css`
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  font: 8px Tahoma;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0;
`;

type OpenMenu = string[] | null;

export function MobileAccountDropdown(props: { onLogout: () => void }) {
  const { logout, isAuthenticated } = useUserState();
  const { cart } = useCart();
  const { wishlist } = useWishlist();
  const { comparison } = useComparison();

  const userMenu = [
    {
      key: "cart",
      label: `Cart (${cart.count})`,
      url: "/cart",
    },
    {
      key: "compare",
      label: `Compare (${comparison.items.length})`,
      url: "/compare",
    },
    {
      key: "wishlist",
      label: `Wishlist (${wishlist.count})`,
      url: "/wishlist",
    },
    {
      key: "my_account",
      label: "My account",
      url: "/my-account",
    },
    {
      key: "my_orders",
      label: "My orders",
      url: "/orders",
    },
    {
      key: `logout`,
      label: "Logout",
      onClick: () => {
        if (logout) {
          logout();
          props.onLogout();
        }
      },
    },
  ];

  const visitorMenu = [
    {
      key: "compare",
      label: `Compare (${comparison.items.length})`,
      url: "/compare",
    },
    {
      key: "signin",
      label: "Sign In",
      onClick: () => {
        Router.push("/login");
      },
    },
    {
      key: "signup",
      label: "Sign Up",
      onClick: () => {
        Router.push("/register");
      },
    },
  ];

  const menu: Array<{
    key: string;
    label: string;
    onClick?: () => void;
    url?: string;
  }> = isAuthenticated ? userMenu : visitorMenu;

  return (
    <div
      css={css`
        color: ${Colors.black};
        text-transform: uppercase;
        letter-spacing: 0.05em;
      `}
    >
      {menu.map((item, index) => {
        return (
          <div
            key={`menu-item--${index}`}
            css={css`
              padding: ${Spacing.l} 0;
              border-bottom: 1px solid ${Colors.light_grey};

              &:last-child {
                border-bottom: none;
              }
            `}
          >
            {item.url && <Link href={item.url}>{item.label}</Link>}
            {item.onClick && (
              <PlainButton onClick={item.onClick}>{item.label}</PlainButton>
            )}
          </div>
        );
      })}
    </div>
  );
}

function findParent(uuid: string, items: MenuItem[]): MenuItem | null {
  const found = items.reduce((found: MenuItem | null, item) => {
    if (found) {
      return found;
    }

    if (item.uuid === uuid) {
      return item;
    }

    if (item.children) {
      return findParent(uuid, item.children);
    }

    return found;
  }, null);

  if (found) {
    return found;
  }

  return null;
}

function getMenuItems(
  headerMenu: Menu | null,
  openMenu: OpenMenu
): {
  main: MenuItem[];
  bottom: MenuItem[];
} {
  if (headerMenu && openMenu) {
    if (!openMenu.length) {
      return {
        main: headerMenu.items,
        bottom: [],
      };
    } else {
      const lastId = openMenu[openMenu.length - 1];
      const parent = findParent(lastId, headerMenu.items);

      if (parent) {
        const children = parent.children || [];
        const items = parent.menu ? parent.menu.items : [];

        return {
          main: children,
          bottom: items,
        };
      }
    }
  }

  return {
    main: [],
    bottom: [],
  };
}

const HeaderMobileItem = (props: {
  item: MenuItem;
  openMenu: (uuid: string) => void;
  closeMenu: () => void;
}) => {
  const { item } = props;
  const url = getUrl(item);
  const { openInteriorDesigner } = useInteriorDesigner();

  return (
    <li
      css={css`
        margin-bottom: ${Spacing.xl};

        &:last-child {
          margin-bottom: 0;
        }
      `}
    >
      {item.type === "serie" || item.menu_item_cover ? (
        <ItemPreview
          item={item}
          onClick={() => {
            if (url) {
              const { href, as } = getLinkParams(url, item.new_window);

              Router.push(href, as);
              props.closeMenu();
            }
          }}
        />
      ) : (
        <a
          onClick={(e) => {
            e.preventDefault();

            if (item.disable_click) {
              return;
            }

            if (item.children && item.children.length) {
              props.openMenu(item.uuid);
            } else if (item.type === "search") {
              Router.push("/search", "/search");
              props.closeMenu();
            } else if (item.type === "interior_designer") {
              openInteriorDesigner();
              props.closeMenu();
            } else if (url) {
              const { href, as } = getLinkParams(url, item.new_window);

              Router.push(href, as);
              props.closeMenu();
            }
          }}
          css={css`
            position: relative;
            font-size: 12px;
            line-height: 20px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 0.15em;
          `}
        >
          {item.name}
        </a>
      )}
    </li>
  );
};

const HeaderMobile = (props: {
  headerStyle?: "DEFAULT" | "DARK" | "OVER";
  announcements: Announcement[];
}) => {
  const [scrolled, setScrolled] = useState(false);
  const [accountVisible, setAccountVisible] = useState(false);
  const [openedMenu, openMenu] = useState<OpenMenu>(null);
  const { headerMobileMenu } = useCommonData();
  const router = useRouter();
  const menuItems = getMenuItems(headerMobileMenu, openedMenu);
  const fixed = props.headerStyle === "DARK" || props.headerStyle === "OVER";
  const darkMode = props.headerStyle === "DARK" && !scrolled;
  const overMode = props.headerStyle === "OVER" && !scrolled;
  const menuVisible = openedMenu !== null;
  const dropdownVisible = menuVisible || accountVisible;
  const themeColor = darkMode && !dropdownVisible ? Colors.white : Colors.black;
  const hasAnnouncements =
    props.announcements && props.announcements.length > 0;

  const isSearchPage = router.asPath === "/search";

  const { cart } = useCart();

  const fixedStyles = css`
    .headroom-wrapper {
      height: auto !important;
    }
    .headroom--unfixed {
      position: absolute !important;
      top: ${hasAnnouncements ? 32 : 0}px !important;
    }
  `;

  useEffect(() => {
    if (accountVisible) {
      openMenu(null);
    }
  }, [accountVisible]);

  useEffect(() => {
    if (openedMenu) {
      setAccountVisible(false);
    }
  }, [openedMenu]);

  useBlockScroll(!!openedMenu || accountVisible);

  return (
    <div
      css={css`
        position: relative;
        z-index: ${HEADER_MOBILE_LEVEL};

        ${fixed && fixedStyles}
      `}
    >
      {hasAnnouncements && (
        <AnnouncementsRenderer announcements={props.announcements} />
      )}
      <Headroom
        pinStart={hasAnnouncements ? 40 : 0}
        onPin={() => {
          setScrolled(true);
          document.body.classList.add("header-pinned");
        }}
        onUnpin={() => {
          document.body.classList.remove("header-pinned");
        }}
        onUnfix={() => setScrolled(false)}
      >
        <header
          css={css`
            width: 100%;
            color: ${themeColor};
            background: ${darkMode || overMode ? "transparent" : "#fff"};
            color: ${themeColor};

            svg {
              path {
                fill: ${themeColor};
              }
            }
          `}
        >
          <Wrapper type="header">
            <div
              css={css`
                position: relative;
                z-index: 2;
                display: flex;
                align-items: center;
                height: 70px;
              `}
            >
              <div
                css={css`
                  width: 50px;
                  flex: 0 0 50px;
                `}
              >
                {openedMenu !== null ? (
                  isArray(openedMenu) && openedMenu.length > 0 ? (
                    <div
                      onClick={() => {
                        const items = openedMenu.slice(0, -1);

                        openMenu(items);
                      }}
                    >
                      <ArrowLeft />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        openMenu(null);
                      }}
                    >
                      <CloseIcon />
                    </div>
                  )
                ) : (
                  <div
                    onClick={() => {
                      openMenu([]);
                    }}
                  >
                    <MenuIcon />
                  </div>
                )}
              </div>
              <div
                css={css`
                  flex: 1 0 30%;
                  text-align: center;

                  svg {
                    width: 80%;
                    max-width: 170px;
                  }
                `}
              >
                <a
                  onClick={() => {
                    openMenu(null);
                    Router.push("/index", "/");
                  }}
                >
                  <LogoIcon />
                </a>
              </div>
              <div
                css={css`
                  width: 25px;
                  flex: 0 0 25px;
                  margin-right: 5px;
                  text-align: center;
                `}
                onClick={() => {
                  if (isSearchPage) {
                    Router.back();
                  } else {
                    Router.push("/search");
                  }
                }}
              >
                {isSearchPage ? <CloseIcon /> : <SearchIcon />}
              </div>
              <div
                css={css`
                  width: 25px;
                  flex: 0 0 25px;
                  text-align: center;
                `}
                onClick={() => {
                  setAccountVisible((state) => !state);
                }}
              >
                {accountVisible ? (
                  <CloseIcon />
                ) : (
                  <div
                    css={css`
                      display: inline-block;
                      position: relative;
                      height: 25px;
                      text-align: center;
                    `}
                  >
                    <BagIcon />
                    {cart.count > 0 && (
                      <span css={iconStyles}>{cart.count}</span>
                    )}
                  </div>
                )}
              </div>
            </div>
            <HeaderMobileDropdown visible={accountVisible}>
              <MobileAccountDropdown
                onLogout={() => {
                  setAccountVisible(false);
                }}
              />
            </HeaderMobileDropdown>
            <HeaderMobileDropdown visible={menuVisible}>
              <nav>
                <ul
                  css={css`
                    list-style: none;
                    padding: 0;

                    li {
                      margin-bottom: ${Spacing.m};

                      &:last-child {
                        margin-bottom: 0;
                      }
                    }
                  `}
                >
                  {menuItems.main.map((item, i) => {
                    return (
                      <HeaderMobileItem
                        item={item}
                        openMenu={(uuid: string) => {
                          openMenu((state) => {
                            return isArray(state) ? [...state, uuid] : [uuid];
                          });
                        }}
                        closeMenu={() => {
                          openMenu(null);
                        }}
                        key={`main-mobile-menu--${i}`}
                      />
                    );
                  })}
                </ul>
                {menuItems.bottom.length > 0 && (
                  <div
                    css={css`
                      margin-top: ${Spacing.xxxl};
                    `}
                  >
                    <Hr size="medium" />

                    <ul
                      css={css`
                        list-style: none;
                        padding: 0;

                        li {
                          margin-bottom: ${Spacing.s};

                          &:last-child {
                            margin-bottom: 0;
                          }
                        }
                      `}
                    >
                      {menuItems.bottom.map((item, i) => {
                        return (
                          <HeaderMobileItem
                            item={item}
                            openMenu={(uuid: string) => {
                              openMenu((state) => {
                                return isArray(state)
                                  ? [...state, uuid]
                                  : [uuid];
                              });
                            }}
                            closeMenu={() => {
                              openMenu(null);
                            }}
                            key={`main-mobile-menu--${i}`}
                          />
                        );
                      })}
                    </ul>
                  </div>
                )}
              </nav>
            </HeaderMobileDropdown>
          </Wrapper>
        </header>
      </Headroom>
    </div>
  );
};

export default HeaderMobile;
