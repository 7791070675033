import React, { useState } from "react";
import { css } from "styled-components";
import { Wrapper } from "./Wrapper";
import { Spacing } from "../helpers/layout";
import { Announcement } from "../types/Announcement";
import useInterval from "@use-it/interval";
import { EditorContent } from "./EditorContent";
import { useResponsive } from "../context/ResponsiveContext";
import moment from "moment";

export function AnnouncementsRenderer(props: {
  announcements: Announcement[];
}) {
  const now = moment();
  const announcements = props.announcements.filter((announcement) => {
    const date = moment(announcement.end_date, "YYYY-MM-DD").endOf("day");

    if (now.isBefore(date)) {
      return true;
    }

    return false;
  });

  const [position, setPosition] = useState(0);
  const announcement = announcements[position];
  const { isDesktop } = useResponsive();

  useInterval(() => {
    if (position < announcements.length - 1) {
      setPosition((position) => position + 1);
    } else {
      setPosition(0);
    }
  }, 8000);

  const isContentEmpty = (val: any) => {
    try {
      return !val || (val.blocks.length === 1 && val.blocks[0].text === "");
    } catch {
      return false;
    }
  };

  return (
    <div
      css={css`
        background-color: black;
        color: white;
        text-align: center;
        padding: ${Spacing.s} 0;
      `}
    >
      <Wrapper>
        <div
          css={css`
            @media only screen and (max-width: 767px) {
              display: flex;
              height: 35px;
              justify-content: center;
              text-align: center;
              overflow: hidden;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
              & > *:first-child {
                margin-top: auto;
              }
              & > *:last-child {
                margin-bottom: auto;
              }
            }

            p {
              font-size: 12px;
              margin: 0;
              letter-spacing: 0.15em;
            }
          `}
        >
          {announcement && (
            <EditorContent
              text={
                !isDesktop && !isContentEmpty(announcement.mobile_text)
                  ? announcement.mobile_text
                  : announcement.desktop_text
              }
            />
          )}
        </div>
      </Wrapper>
    </div>
  );
}
