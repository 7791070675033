import React from "react";
import { MenuItem } from "../types/Menu";
import { Row, Spacing, Column } from "../helpers/layout";
import { css } from "styled-components";
import { Link, getUrl } from "./Link";
import ArrowTiny from "../static/images/arrowTiny.png";
import { Hr } from "./Hr";
import { useInteriorDesigner } from "../context/InteriorDesignerContext";

function Item(props: { item: MenuItem }) {
  const { item } = props;
  const { openInteriorDesigner } = useInteriorDesigner();
  const url = getUrl(item);

  const linkStyle = css`
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.15em;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    left: -35px;
    transition: all 400ms;
    cursor: pointer;

    .arrow {
      margin-right: ${Spacing.m};
      position: relative;
      opacity: 0;
      visibility: hidden;
    }
    &:hover {
      left: 0;

      .arrow {
        opacity: 1;
        visibility: visible;
      }
    }
  `;

  if (url) {
    return (
      <Link
        href={url}
        external={item.new_window}
        disable={item.disable_click}
        css={linkStyle}
        noEffect
      >
        <Row gutter={Spacing.none}>
          <span className="arrow">
            <img src={ArrowTiny} width="20px" alt="Arrow" />
          </span>
          <span>{item.name}</span>
        </Row>
      </Link>
    );
  }

  if (item.type == "interior_designer") {
    return (
      <Link
        href=""
        external={item.new_window}
        disable={item.disable_click}
        css={linkStyle}
        noEffect
        onClick={() => {
          openInteriorDesigner();
        }}
      >
        <Row gutter={Spacing.none}>
          <span className="arrow">
            <img src={ArrowTiny} width="20px" alt="Arrow" />
          </span>
          <span>{item.name}</span>
        </Row>
      </Link>
    );
  }

  return null;
}

export function HeaderBottomMenu(props: { items: MenuItem[] }) {
  return (
    <div>
      <Hr size="medium" />
      <Column
        gutter={Spacing.m}
        css={css`
          margin-top: ${Spacing.l};
        `}
      >
        {props.items.map((item, i) => {
          return <Item item={item} key={i} />;
        })}
      </Column>
    </div>
  );
}
