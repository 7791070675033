import React from "react";
import { css } from "styled-components";
import { Colors } from "../branding";
import { useWrapper } from "../helpers/useWrapper";
import { Spacing } from "../helpers/layout";
import { useResponsive } from "../context/ResponsiveContext";

export function HeaderMobileDropdown(props: {
  visible: boolean;
  children: React.ReactNode | React.ReactNode[];
}) {
  const spacing = useWrapper();
  const { isIPhone, isIPad } = useResponsive();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        position: absolute;
        width: ${props.visible ? "100%" : "0%"};
        height: 100vh;
        overflow: hidden;
        top: 0;
        left: 0;
        background: ${Colors.very_light_grey};
        opacity: ${props.visible ? 1 : 0};
        visibility: ${props.visible ? "visible" : "hidden"};
        color: ${Colors.black};
        transition: all 500ms;
        &:before {
          content: "";
          display: block;
          background-color: ${Colors.white};
          height: 70px;
          width: 100%;
        }
      `}
    >
      <div
        css={css`
          position: relative;
          width: 100vw;
          flex: 1;
          overflow: scroll;
          padding: 0 ${spacing} ${isIPhone || isIPad ? Spacing.xxxxl : 0};
          box-sizing: border-box;
        `}
      >
        {props.children}
      </div>
    </div>
  );
}
