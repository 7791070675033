import { notAuthorised } from "../request";
import { Menu } from "../types/Menu";
import { HelpText } from "../types/HelpText";
import { Settings } from "../types/Settings";
import { defaultSettings } from "../context/CommonDataContext";
import { IncomingMessage } from "http";
import { CommonData } from "../types/CommonData";

export async function getCommonData(
  req: IncomingMessage | undefined,
  page?: "PRODUCT"
) {
  let headerMenu = null;
  let headerMobileMenu = null;
  let footerMenu = null;
  let footerSmallMenu = null;
  let helpTexts: HelpText[] = [];
  let settings: Settings = defaultSettings;
  let userAgent = "";
  let howYouFoundDrew = null;

  try {
    const settingsRequest = await notAuthorised.request<Settings>({
      method: "GET",
      url: `/api/frontend/settings/?location=${page}`,
    });

    settings = settingsRequest.data;
  } catch {
    settings = defaultSettings;
  }

  try {
    const menuRequestUrl = settings.gallery_focus
      ? `/api/frontend/menus/header-gallery-focus/`
      : `/api/frontend/menus/header/`;
    const headerMenuRequest = await notAuthorised.request<Menu>({
      method: "GET",
      url: menuRequestUrl,
    });
    headerMenu = headerMenuRequest.data;
  } catch {
    headerMenu = null;
  }
  try {
    const menuRequestUrl = settings.gallery_focus
      ? `/api/frontend/menus/header-mobile-gallery-focus/`
      : `/api/frontend/menus/header-mobile/`;
    const headerMobileMenuRequest = await notAuthorised.request<Menu>({
      method: "GET",
      url: menuRequestUrl,
    });
    headerMobileMenu = headerMobileMenuRequest.data;
  } catch {
    headerMobileMenu = null;
  }

  try {
    const footerMenuRequest = await notAuthorised.request<Menu>({
      method: "GET",
      url: `/api/frontend/menus/footer/`,
    });
    footerMenu = footerMenuRequest.data;
  } catch {
    footerMenu = null;
  }

  try {
    const footerSmallMenuRequest = await notAuthorised.request<Menu>({
      method: "GET",
      url: `/api/frontend/menus/footer-small/`,
    });
    footerSmallMenu = footerSmallMenuRequest.data;
  } catch {
    footerSmallMenu = null;
  }

  try {
    const howYouFoundDrewRequest = await notAuthorised.request<Menu>({
      method: "GET",
      url: `/api/frontend/menus/how-you-found-drew/`,
    });
    howYouFoundDrew = howYouFoundDrewRequest.data;
  } catch (error) {
    howYouFoundDrew = null;
  }

  try {
    const helpTextsRequest = await notAuthorised.request<HelpText[]>({
      method: "GET",
      url: `/api/frontend/help-texts/`,
    });
    helpTexts = helpTextsRequest.data;
  } catch {
    helpTexts = [];
  }

  if (req) {
    // if you are on the server and you get a 'req' property from your context
    userAgent = req.headers["user-agent"] || ""; // get the user-agent from the headers
  } else {
    userAgent = navigator.userAgent; // if you are on the client you can access the navigator from the window object
  }

  const commonData: CommonData = {
    howYouFoundDrew,
    headerMenu,
    headerMobileMenu,
    footerMenu,
    footerSmallMenu,
    helpTexts,
    settings,
    userAgent,
  };

  return commonData;
}
